var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('small',{staticClass:"text-muted"},[_vm._v("Current Page: "+_vm._s(_vm.currentPage)+". Total rows: "+_vm._s(_vm.rows))]),(_vm.rows > 0)?_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"size":"sm","align":"right","limit":"10"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('b-table',{attrs:{"bordered":"","small":"","filter":_vm.filter,"filter-included-fields":_vm.filterOn,"filter-debounce":"500","responsive":true,"items":_vm.chatIds,"fields":[
        { key: 'intentName', label: 'Intent Name' },
        { key: 'category', label: 'Category' },
        { key: 'subCategory', label: 'Sub Category' },
        { key: 'vendor', label: 'Vendor' },
        { key: 'chatId', label: 'Chat Id' },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'count', label: 'Count', sortable: true },
      ],"per-page":_vm.perPage,"current-page":_vm.currentPage,"busy":_vm.isBusy,"sort-by":"date","sort-desc":true},scopedSlots:_vm._u([{key:"cell(chatId)",fn:function(data){return [_c('div',{staticClass:"text-truncate table-chat-id"},[_c('span',{staticClass:"text-primary",on:{"click":function($event){return _vm.searchChatId(data.value)}}},[_vm._v(_vm._s(data.value))])])]}},{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.value))+" ")]}}])}),(_vm.rows > 0)?_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"size":"sm","align":"right","limit":"10"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('b-modal',{ref:"chat-modal",attrs:{"size":"lg","scrollable":"","hide-footer":"","header-class":"chat-id-header","title-tag":"div","title-class":"width-100","centered":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('span',[_vm._v("Chat ID:")]),_c('input',{ref:"chat-id",staticClass:"chat-id",staticStyle:{"cursor":"pointer"},attrs:{"readonly":""},domProps:{"value":_vm.chatId},on:{"click":_vm.selectChatId}})]},proxy:true}])},[_c('chat-text',{attrs:{"chats":_vm.chats}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }