<template>
  <div>
<!--    <b-row>-->
<!--      <b-col>-->
<!--        <b-form-group-->
<!--          label="Filter"-->
<!--          label-for="filter-input"-->
<!--          label-cols-sm="3"-->
<!--          label-align-sm="right"-->
<!--          label-size="sm"-->
<!--          class="mb-0"-->
<!--        >-->
<!--          <b-input-group size="sm">-->
<!--            <b-form-input-->
<!--              id="filter-input"-->
<!--              v-model="filter"-->
<!--              type="search"-->
<!--              placeholder="Type to Search"-->
<!--            ></b-form-input>-->

<!--            <b-input-group-append>-->
<!--              <b-button :disabled="!filter" @click="filter = ''"-->
<!--                >Clear-->
<!--              </b-button>-->
<!--            </b-input-group-append>-->
<!--          </b-input-group>-->
<!--        </b-form-group>-->
<!--      </b-col>-->
<!--    </b-row>-->
    <small class="text-muted"
      >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
    >
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      size="sm"
      align="right"
      limit="10"
      v-if="rows > 0"
    ></b-pagination>
    <b-table
      bordered
      small
      :filter="filter"
      :filter-included-fields="filterOn"
      filter-debounce="500"
      :responsive="true"
      :items="chatIds"
      :fields="[
        { key: 'intentName', label: 'Intent Name' },
        { key: 'category', label: 'Category' },
        { key: 'subCategory', label: 'Sub Category' },
        { key: 'vendor', label: 'Vendor' },
        { key: 'chatId', label: 'Chat Id' },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'count', label: 'Count', sortable: true },
      ]"
      :per-page="perPage"
      :current-page="currentPage"
      :busy="isBusy"
      sort-by="date"
      :sort-desc="true"
    >
      <template v-slot:cell(chatId)="data">
        <div class="text-truncate table-chat-id">
          <span class="text-primary" @click="searchChatId(data.value)">{{
            data.value
          }}</span>
        </div>
      </template>
      <template v-slot:cell(date)="data">
        {{ data.value | date }}
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      size="sm"
      align="right"
      limit="10"
      v-if="rows > 0"
    ></b-pagination>

    <b-modal
      size="lg"
      scrollable
      hide-footer
      ref="chat-modal"
      header-class="chat-id-header"
      title-tag="div"
      title-class="width-100"
      centered
    >
      <template v-slot:modal-title>
        <span>Chat ID:</span>
        <input
          ref="chat-id"
          class="chat-id"
          style="cursor: pointer"
          readonly
          @click="selectChatId"
          :value="chatId"
        />
      </template>
      <chat-text :chats="chats" />
    </b-modal>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import ChatText from '@/components/ChatHistories/ChatText';

export default {
  name: 'IntentSummaryDetail',
  mixins: [httpclient],
  components: { ChatText },
  props: ['chatIds'],
  data() {
    return {
      chats: [],
      perPage: 100,
      currentPage: 1,
      filter: null,
      filterOn: ['chatId'],
    };
  },
  computed: {
    rows() {
      return this.chatIds.length;
    },
  },
  methods: {
    searchChatId(chatId) {
      this.chatId = chatId;
      this.httpclient
        .post('api/chathistories/getlist', { chatId: chatId, limit: 1000 })
        .then((resp) => {
          this.chats = resp.data;
          this.$refs['chat-modal'].show();
        })
        .catch(() => {
          this.chats = [];
        });
    },
    selectChatId() {
      this.$refs['chat-id'].focus();
      this.$refs['chat-id'].select();
    },
  },
};
</script>

<style scoped>
.table-chat-id {
  cursor: pointer;
  width: 100px;
  display: inline-block;
}

.chat-id-header > .modal-title {
  width: 100%;
}

.chat-id {
  width: calc(100% - 80px);
  font-size: 0.85rem;
  display: inline-block;
  background: none;
  border: none;
  margin-left: 1em;
}
</style>