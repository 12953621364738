<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <h1>Intent Summary</h1>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">Bot</b-col>
      <b-col cols="4">
        <bot-selector
          @botChanged="
              (botId) => {
                searchParams.botId = botId;
              }
            "
        />
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row>
    <b-row>
      <b-col cols="2">Duration</b-col>
      <b-col>
        <b-form-group>
          <b-form-radio-group v-model="duration" name="duration">
            <b-form-radio value="byDay">By day</b-form-radio>
            <b-form-radio value="byMonth"
              >By month
              <small class="text-muted"
                >(result will show the latest 3 months)</small
              ></b-form-radio
            >
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="duration === 'byDay'" class="mb-3">
      <b-col cols="2">Date Range</b-col>
      <b-col cols="4">
        <date-range-picker
          ref="picker"
          :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
          :minDate="minDate"
          :maxDate="maxDate"
          :ranges="defaultRanges()"
          v-model="dateRange"
        >
          <div slot="input" slot-scope="picker" class="form-inline">
            <small class="text-muted mr-1">from</small>
            <span class="form-control form-control-sm">{{
              picker.startDate | date
            }}</span>
            <small class="text-muted mx-1">to</small>
            <span class="form-control form-control-sm">{{
              picker.endDate | date
            }}</span>
          </div>
        </date-range-picker>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">Category</b-col>
      <b-col cols="4">
        <b-form-select
          v-model="searchParams.category"
          :options="categories"
          size="sm"
        ></b-form-select>
      </b-col>
      <b-col cols="2">Sub Category</b-col>
      <b-col cols="4">
        <b-form-select
          v-model="searchParams.subCategory"
          :options="subCategories"
          size="sm"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">Intent Id</b-col>
      <b-col cols="4">
        <b-input
          size="sm"
          v-model="searchParams.intentId"
          @change="searchParams.intentId = _numeric($event)"
        ></b-input>
      </b-col>
      <b-col cols="2">Intent Name</b-col>
      <b-col cols="4">
        <b-input size="sm" v-model="searchParams.intentName"></b-input>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-button
          size="sm"
          variant="primary"
          class="float-right"
          @click="getData"
          >Search
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <small class="text-muted"
          >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
        >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="sm"
          align="right"
          limit="10"
          v-if="rows > 0"
        ></b-pagination>
        <b-table
          bordered
          small
          :responsive="true"
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusy"
          sticky-header="60vh"
          :no-border-collapse="true"
          class="intent-summary-table"
          sort-by="total"
          :sort-desc="true"
        >
          <template
            v-for="dateSlot in dateSlots"
            v-slot:[dateSlot.slotName]="data"
          >
            <span
              :key="dateSlot.slotName"
              class="clickable text-primary"
              @click="showChatIds(data.item, dateSlot.value)"
              >{{ data.value }}</span
            >
          </template>
          <template v-slot:cell(total)="data">
            <span
              class="clickable text-primary"
              @click="showChatIds(data.item)"
              >{{ data.value }}</span
            >
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-modal
      size="lg"
      scrollable
      hide-footer
      ref="chat-ids-modal"
      header-class="chat-id-header"
      centered
    >
      <template v-slot:modal-title>
        <b-button size="sm" @click="exportChats" variant="outline-primary">
          <b-icon-file-earmark-excel />
          Export
        </b-button>
      </template>
      <intent-summary-detail :chatIds="chatIds"></intent-summary-detail>
    </b-modal>
  </div>
</template>

<script>
/*global _*/
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import httpclient from '@/mixins/HttpHandler';
import dayjs from 'dayjs';
import IntentSummaryDetail from '@/components/ChatHistories/IntentSummaryDetail';
import BotSelector from '@/components/UI/BotSelector';

export default {
  name: 'IntentSummary',
  components: { DateRangePicker, IntentSummaryDetail, BotSelector },
  mixins: [httpclient],
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.searchParams.botId = botId;

    let datepicker = document.getElementsByClassName('reportrange-text');
    datepicker[0].classList.add('form-control-sm');

    this.dateRange = {
      startDate: dayjs().startOf('day').toDate(),
      endDate: dayjs().add(1, 'days').startOf('day').toDate(),
    };
  },
  data() {
    const fields = _.cloneDeep(_defaultFields);
    return {
      isBusy: false,
      isByMonth: false,
      bots: [],
      searchParams: {
        botId: -1,
        category: '',
        subCategory: '',
        intentId: null,
        intentName: '',
      },
      duration: 'byDay',
      dateRange: {},
      dateSlots: [],
      fields: fields,
      items: [],
      perPage: 100,
      currentPage: 1,
      chatId: '',
      chatIds: [],
      chats: [],
      exportParams: {
        intentId: -1,
        dateFrom: null,
        dateTo: null,
        clickActionType: null,
      },
      categories: [],
      subCategories: [],
    };
  },
  computed: {
    minDate() {
      return dayjs().add(-2, 'months').set('date', 1).startOf('day').toDate();
    },
    maxDate() {
      return dayjs().add(1, 'days').toDate();
    },
    rows() {
      return this.items.length;
    },
  },
  watch: {
    'searchParams.botId': function (val) {
      if (val) {
        this.items = [];
        this.getCategories();
        this.getSubCategories();
      }
    },
    duration() {
      this.items = [];
    },
    dateRange() {
      this.items = [];
    },
  },
  methods: {
    getData() {
      let searchParams = _.cloneDeep(this.searchParams);
      if (this.duration === 'byDay') {
        let dateRange = _.cloneDeep(this.dateRange);
        searchParams.dateFrom = dayjs(dateRange.startDate).format('YYYY-MM-DD');
        searchParams.dateTo = dayjs(dateRange.endDate).format('YYYY-MM-DD');

        this.httpclient
          .get('api/intentSummary', { params: searchParams })
          .then((resp) => {
            if (resp.data) {
              let rows = resp.data;
              let items = [];

              const defaultFields = _.cloneDeep(_defaultFields);
              const dates = [];
              for (
                let d = dateRange.startDate;
                d < dateRange.endDate;
                d.setDate(d.getDate() + 1)
              ) {
                dates.push(dayjs(d).format('YYYY-MM-DD'));
                defaultFields.push({
                  label: dayjs(d).format('YYYY-MM-DD'),
                  key: dayjs(d).format('YYYY-MM-DD'),
                  sortable: true,
                });
              }
              this.dateSlots = _.map(dates, (date) => {
                return { slotName: 'cell(' + date + ')', value: date };
              });
              defaultFields.push({
                key: 'total',
                label: 'Total',
                sortable: true,
              });

              let groups = _.groupBy(rows, (row) => {
                return '' + row.clickActionType + '|' + row.intentId;
              });
              _.each(groups, (values) => {
                let total = 0;
                let item = {};
                item.intentId = values[0].intentId;
                item.intentName = values[0].intentName;
                item.category = values[0].category;
                item.subCategory = values[0].subCategory;
                item.vendor = values[0].vendor;
                item.clickActionType = values[0].clickActionType;
                _.each(dates, (date) => {
                  let data = _.find(values, (value) => {
                    return dayjs(value.date).format('YYYY-MM-DD') === date;
                  });
                  item[date] = data ? data.count : null;
                  total = total + (data ? data.count : 0);
                });
                item.total = total;
                items.push(item);
              });

              this.fields = defaultFields;
              this.currentPage = 1;
              this.items = items;
            } else {
              this.items = [];
            }
          })
          .catch(() => {
            this.items = [];
          });
      } else if (this.duration === 'byMonth') {
        this.httpclient
          .get('api/intentSummary/byMonth', { params: searchParams })
          .then((resp) => {
            if (resp.data) {
              let rows = resp.data;
              let items = [];

              const defaultFields = _.cloneDeep(_defaultFields);
              const dates = [];
              let months = 2;
              let currentDate = dayjs().add(-months, 'months').date(1);

              for (let x = 0; x <= months; x++) {
                let d = dayjs(currentDate);
                dates.push(dayjs(d).format('YYYY-MM-DD'));
                defaultFields.push({
                  label: dayjs(d).format('MMM YYYY'),
                  key: dayjs(d).format('YYYY-MM-DD'),
                  sortable: true,
                });
                currentDate = dayjs(currentDate).add(1, 'months');
              }

              this.dateSlots = _.map(dates, (date) => {
                return { slotName: 'cell(' + date + ')', value: date };
              });
              defaultFields.push({
                key: 'total',
                label: 'Total',
                sortable: true,
              });

              let groups = _.groupBy(rows, (row) => {
                return '' + row.clickActionType + '|' + row.intentId;
              });
              _.each(groups, (values) => {
                let total = 0;
                let item = {};
                item.intentId = values[0].intentId;
                item.intentName = values[0].intentName;
                item.category = values[0].category;
                item.subCategory = values[0].subCategory;
                item.vendor = values[0].vendor;
                item.clickActionType = values[0].clickActionType;
                _.each(dates, (date) => {
                  let data = _.find(values, (value) => {
                    return dayjs(value.date).format('YYYY-MM-DD') === date;
                  });
                  item[date] = data ? data.count : null;
                  total = total + (data ? data.count : 0);
                });
                item.total = total;
                items.push(item);
              });

              this.fields = defaultFields;
              this.currentPage = 1;
              this.items = items;
            } else {
              this.items = [];
            }
          })
          .catch(() => {
            this.items = [];
          });
      }
    },
    showChatIds(row, date) {
      let searchParams = _.cloneDeep(this.searchParams);
      let dateRange = _.cloneDeep(this.dateRange);
      if (this.duration === 'byDay') {
        if (date) {
          searchParams.dateFrom = dayjs(date).format('YYYY-MM-DD');
          searchParams.dateTo = dayjs(date).add(1, 'days').format('YYYY-MM-DD');
        } else {
          searchParams.dateFrom = dayjs(dateRange.startDate).format(
            'YYYY-MM-DD'
          );
          searchParams.dateTo = dayjs(dateRange.endDate).format('YYYY-MM-DD');
        }
      } else if (this.duration === 'byMonth') {
        if (date) {
          searchParams.dateFrom = dayjs(date).format('YYYY-MM-DD');
          searchParams.dateTo = dayjs(date)
            .add(1, 'month')
            .format('YYYY-MM-DD');
        } else {
          searchParams.dateFrom = dayjs()
            .set('date', 1)
            .add(-2, 'month')
            .format('YYYY-MM-DD');
          searchParams.dateTo = dayjs()
            .set('date', 1)
            .add(1, 'month')
            .format('YYYY-MM-DD');
        }
      }

      searchParams.intentId = row.intentId;
      searchParams.clickActionType = row.clickActionType;
      this.exportParams.intentId = row.intentId;
      this.exportParams.dateFrom = searchParams.dateFrom;
      this.exportParams.dateTo = searchParams.dateTo;
      this.exportParams.clickActionType = row.clickActionType;
      this.httpclient
        .get('api/intentSummary/chatIds', { params: searchParams })
        .then((resp) => {
          if (resp.data) {
            this.chatIds = _.map(resp.data, (item) => {
              return {
                ...{
                  intentName: row.intentName,
                  category: row.category,
                  subCategory: row.subCategory,
                  vendor: row.vendor,
                },
                ...item,
              };
            });
            this.$refs['chat-ids-modal'].show();
          } else {
            this.chatIds = [];
          }
        })
        .catch(() => {
          this.chatIds = [];
        });
    },
    exportChats() {
      // let searchParams = _.cloneDeep(this.searchParams);
      // let dateRange = _.cloneDeep(this.dateRange);
      // searchParams.dateFrom = dayjs(dateRange.startDate).format('YYYY-MM-DD');
      // searchParams.dateTo = dayjs(dateRange.endDate).format('YYYY-MM-DD');
      let searchParams = { ...this.searchParams, ...this.exportParams };
      this.isBusy = true;
      this._downloadFile('api/intentSummary/export', searchParams, () => {
        this.isBusy = false;
      });
    },
    getCategories() {
      const botId = this.searchParams.botId;
      this.httpclient
        .get('api/intentSummary/categories', { params: { botId: botId } })
        .then((resp) => {
          this.categories = [
            ...[{ value: null, text: 'Select' }],
            ...resp.data,
          ];
          this.searchParams.category = null;
        });
    },
    getSubCategories() {
      const botId = this.searchParams.botId;
      this.httpclient
        .get('api/intentSummary/subcategories', { params: { botId: botId } })
        .then((resp) => {
          this.subCategories = [
            ...[{ value: null, text: 'Select' }],
            ...resp.data,
          ];
          this.searchParams.subCategory = null;
        });
    },
    defaultRanges() {
      const today = dayjs().startOf('day').toDate();
      const tomorrow = dayjs().add(1, 'days').startOf('day').toDate();
      const yesterday = dayjs().add(-1, 'days').startOf('day').toDate();
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonday = dayjs().startOf('isoWeek');
      const prevMonday = dayjs().add(-7, 'days').startOf('isoWeek');
      return {
        Today: [today, tomorrow],
        Yesterday: [yesterday, today],
        'Previous Week': [prevMonday, thisMonday],
        'This Week': [thisMonday, tomorrow],
        'This Month': [thisMonthStart, tomorrow],
      };
    },
  },
};

const _defaultFields = [
  {
    key: 'intentId',
    label: 'Intent Id',
    sortable: false,
  },
  {
    key: 'intentName',
    label: 'Intent Name',
    sortable: true,
    stickyColumn: true,
  },
  {
    key: 'category',
    label: 'Category',
    sortable: true,
  },
  {
    key: 'subCategory',
    label: 'Sub Category',
    sortable: true,
  },
  {
    key: 'vendor',
    label: 'Vendor',
    sortable: true,
  },
  {
    key: 'clickActionType',
    label: 'Click Action Type',
    sortable: true,
  },
];
</script>

<style scoped>
.intent-summary-table {
  height: 60vh;
}
</style>
